import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table-col"
}
const _hoisted_2 = { class: "table-col" }
const _hoisted_3 = {
  class: "flex align-items-baseline",
  title: "Port for the device"
}
const _hoisted_4 = { class: "table-col" }
const _hoisted_5 = {
  class: "flex align-items-baseline",
  title: "Is the device currently active"
}
const _hoisted_6 = { class: "table-col w-1" }
const _hoisted_7 = {
  class: "flex align-items-baseline",
  title: "Calibration for the device"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkLabel = _resolveComponent("LinkLabel")!
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$props.computer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_LinkLabel, {
            id: $props.measurerSetting.computer.id,
            value: $props.measurerSetting.computer.alias,
            type: "computer"
          }, null, 8, ["id", "value"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_mdicon, {
          name: "midi-port",
          class: "mr-2"
        }),
        _createTextVNode(" " + _toDisplayString($props.measurerSetting.port), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        ($props.measurerSetting.active)
          ? (_openBlock(), _createBlock(_component_mdicon, {
              key: 0,
              name: "power",
              class: "mr-2",
              style: {"color":"green"}
            }))
          : (_openBlock(), _createBlock(_component_mdicon, {
              key: 1,
              name: "power",
              class: "mr-2",
              style: {"color":"red"}
            }))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, " Calibration: " + _toDisplayString($props.measurerSetting.calibration), 1)
    ])
  ], 64))
}