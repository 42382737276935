
import LinkLabel from "@/components/navigation/LinkLabel.vue"
export default {
  name: "MeasurerSettingsRow",
  components: { LinkLabel },
  props: {
    measurerSetting: {
      type: Object,
      default: null,
    },
    computer: {
      type: Object,
      default: null,
    },
  },
}
